/**
 * Layout is a default page wrapper defining the major sections of the site.
 * Supported props: config, ctx, currentpath
 */

import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Banner from './Banner';
import Nav from './NavToggleFlex';
import FixedPanel from './FixedPanel';
import PageSection from './PageSection';
import Screener from './Screener';
import Footer from './FooterSimple';
import { CookieProvider } from './CookieContext';
import { setLinks } from '../util/pagetools';
import { mergeSectionsWithImages } from '../util/rendertools';
import { localizer } from '../util/localizer';
import ScrollToTop from './ScrollToTop';

export default function Layout(props) {
  const cfg = props.config;
  const localized = localizer(props.ctx);
  const toplinks = setLinks(cfg.sections);
  const footdata = {
    ...cfg.footer,
    toplinks,
    sitename: cfg.sitename,
    siteurl: cfg.siteurl
  };

  const {title, subtitle, startstudy, banner} = cfg;
  const sections = mergeSectionsWithImages(
    cfg.sections.map((section, index) => <PageSection key={section.id} config={section} />),
    getImages,
    [1,6]
  );

  return (
    <div id="page">
      <CookieProvider content={cfg.cookie}>
        <div id="layout-root">
          <header id="top">
            <Nav toplinks={toplinks}
                 sitename={cfg.sitename}
                 navconfig={cfg.navbar}
                 ctx={props.ctx}
                 currentpath={props.currentpath}
                 localizerfn={localized} />
            <FixedPanel src={cfg.fixedpanel} btnLabel={cfg.startstudy} visibleAt="banner" hideAt="screener" />
            <ScrollToTop visibleAt="banner" hideAt="screener" />
            <Banner src={{title, subtitle, startstudy, banner}} />
          </header>
          <main>
            {sections}
            <Screener config={cfg.screener} sitedata={cfg} localizerfn={localized} />
          </main>
          {props.children}
          <Footer src={footdata} localizerfn={localized} />
        </div>
      </CookieProvider>
    </div>
  );
};

function getImages() {
  function makeDiv(elem, id) { return <div className="-fluid" id={id} key={id}>{elem}</div>; }
  const imgs = [
    makeDiv(<StaticImage src='../images/ctximg_1.png' alt="" className="ctximg" />, 'img-1'),
    makeDiv(<StaticImage src='../images/ctximg_2.png' alt="" className="ctximg" />, 'img-2')
  ];
  return function() { return imgs.shift(); }
}
