/* Utilities for general rendering tasks. */
import React from 'react';
import Safe from '../components/Safe';
import { splicer } from './pagetools';

function renderParagraphs(items, theme) {
  if (!Array.isArray(items)) return null;
  const rootAttribs = { type: 'p' };
  if (theme) rootAttribs.className = theme;
  return items.map((item, index) => <Safe {...rootAttribs} key={`p_${index}`} content={item} />);
}

function mergeSectionsWithImages(sections, imgFn, imageIndexes) {
  if (!imgFn) return sections;
  else if (!Array.isArray(imageIndexes)) return sections;
  const img = imgFn();
  const spl = splicer(sections);
  imageIndexes.forEach(idx => { spl.at(img(), idx); });
  return spl.get();
}

function inspect(cfg) {
  if (!cfg) return <div>No object specified to inspect</div>;
  return (
    <div>
      <h2>Object Detail</h2>
      <code>{JSON.stringify(cfg,null,2)}</code>
    </div>
  );
}

export { renderParagraphs, mergeSectionsWithImages, inspect };
