import React, { useState, useEffect } from 'react';
import { css } from '../util/pagetools';

export default function ScrollToTop(props) {
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    const scrollFn = scrollMonitor(props.visibleAt, props.hideAt);
    function enablePanel() { setHidden(scrollFn()); }
    function watchScroll() { window.addEventListener('scroll', enablePanel); }
    watchScroll();
    // Remove the listener
    return () => { window.removeEventListener('scroll', enablePanel); };
  });

  return (
    <a href="#top">
      <img
        className={css('scroll-to-top', (hidden?'hidx':''))} 
        src="carrot_graphic_dark_blue_drop_shadow_V3.png" alt="Scroll to Top" 
      />
    </a>
  );
};

/**
 * Determine when scrolling enters or exits a given range, specified by one
 * or two elements whose positions represent lower and upper bounds.
 * @param string - id of element of the lower bound
 * @param string - id of element of the upper bound
 * @return boolean
 */
function scrollMonitor(lowerElement, upperElement) {
  function getTop(el) { return el.getBoundingClientRect().top + (window.pageYOffset || document.documentElement.scrollTop); }
  const lowerEl = lowerElement ? document.getElementById(lowerElement) : null;
  const upperEl = upperElement ? document.getElementById(upperElement) : null;
  return function() {
    const lowerBoundary = (lowerEl ? lowerEl.scrollHeight+getTop(lowerEl) : 0);
    const upperBoundary = (upperEl ? getTop(upperEl)-200 : 0);
    const hideIt = (upperBoundary>0 ? (window.scrollY<lowerBoundary || window.scrollY>upperBoundary) : (window.scrollY<lowerBoundary));
    // if (window.scrollY%10===0) console.log(`Hide panel? ${hideIt?'Y':'N'}, scrollY=${window.scrollY} low=${lowerBoundary}, hi=${upperBoundary}`);
    return hideIt;
  };
}
