
export default function init() {
  const formValidators = {};
  const refs = {};

  function addValidation(fieldname, rule, ref) {
    formValidators[fieldname] = rule || /(.|\s)*\S(.|\s)*/;
    refs[fieldname] = ref;
  }

  function setNonBlank(fieldname, ref) { addValidation(fieldname, /(.|\s)*\S(.|\s)*/, ref); }

  function removeValidation(fieldname) {
    if (formValidators.hasOwnProperty(fieldname)) {
      delete formValidators[fieldname];
    }
    if (refs.hasOwnProperty(fieldname)) {
      delete refs[fieldname];
    }
  }

  function info() {
    return `Fields ${Object.keys(formValidators).join(',')}, focus refs: ${Object.keys(refs).join(',')}`;
  }

  function getRule(fieldname) { return formValidators[fieldname]; }
  function getRef(fieldname) { return refs[fieldname]; }
  function fields() { return Object.keys(formValidators); }

  return { addValidation, setNonBlank, removeValidation, info, getRule, getRef, fields };
};
