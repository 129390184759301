/* Utility functions for study locations */

/**
 * Calculate haversine distance between two coordinates.
 * @param object - a coordinate object requiring attributes 'lat' and 'lng'
 * @return number
 * @see https://cloud.google.com/blog/products/maps-platform/how-calculate-distances-map-maps-javascript-api
 */
function hdist(p1, p2) {
  var R = 3958.8; // Radius of the Earth in miles
  var rlat1 = p1.lat*(Math.PI/180); // Convert degrees to radians
  var rlat2 = p2.lat*(Math.PI/180); // Convert degrees to radians
  var difflat = rlat2-rlat1; // Radian difference (latitudes)
  var difflon = (p2.lng-p1.lng)*(Math.PI/180); // Radian difference (longitudes)
  var d = 2*R*Math.asin(Math.sqrt(Math.sin(difflat/2)*Math.sin(difflat/2)+Math.cos(rlat1)*Math.cos(rlat2)*Math.sin(difflon/2)*Math.sin(difflon/2)));
  return Math.round(d);
}

/**
 * Transform a collection of location objects by adding a distance to a given point.
 * @param object - a coordinate object
 * @param array - an array of coordinate objects
 * @return array
 */
export default function sortByDistance(point, locations) {
  if (!point) return locations;
  function distsort(a,b) { if (a.dist > b.dist) return 1; if (a.dist < b.dist) return -1; return 0; }
  const locplus = locations.map(loc => ({
    ...loc,
    dist: hdist(loc, point)
  }));
  return locplus.sort(distsort);
};
