/**
 * Render a form from a configuration file that enumerates its fields.
 * Supported props: config onSubmit btnlabel isQualified
 */
import React, { useState } from 'react';
import formtool from '../util/formManager';
import { css } from '../util/pagetools';

export default function FlexForm(props) {
  const [formdata, setFormdata] = useState({});
  const [addressData, setAddressData] = useState({});
  const ft = formtool(props.config.formfields, formdata, setFormdata, addressData, setAddressData, props.isQualified);

  function onSubmit(e) {
    e.preventDefault();
    const formOk = ft.validateForm();
    if (!formOk) return;
    else if (!props.onSubmit) return;
    const payload = Object.assign({}, formdata, addressData);
    props.onSubmit(payload);
  }

  return (
    <form className="form-root patient-form">
      {ft.render()}
      <div className="form-btn-container">
        {props.config.helptext && <p>* {props.config.helptext}</p>}
        <button className={css('form-btn', props.config.button.theme)} onClick={onSubmit}>{props.btnlabel}</button>
      </div>
    </form>
  );
};
