/**
 * Render a date picker.
 * Supported props: name value onChange onBlur theme placeholder
 */
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { css } from '../util/pagetools';

export default function(props) {
  const years = getYearsBack(120);
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  function renderCustomHeader(config) {
    const {date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled} = config;
    function prevMonth(e) { e.preventDefault(); decreaseMonth(e); }
    function nextMonth(e) { e.preventDefault(); increaseMonth(e); }

    return (
      <div className="dp-header">
        <button onClick={prevMonth} disabled={prevMonthButtonDisabled}>{"<"}</button>
        <select value={getYear(date)} onChange={(e) => changeYear(e.target.value)} >
          {years.map((option) => (<option key={option} value={option}>{option}</option>))}
        </select>
        <select value={months[getMonth(date)]} onChange={(e) => changeMonth(months.indexOf(e.target.value))} >
          {months.map((option) => (<option key={option} value={option}>{option}</option>))}
        </select>
        <button onClick={nextMonth} disabled={nextMonthButtonDisabled}>{">"}</button>
      </div>
    );
  }

  const attribs = {
    id: props.name,
    renderCustomHeader: renderCustomHeader,
    selected: props.value,
    onChange: props.onChange,
    className: css('calendar-input-field', props.theme),
    placeholderText: props.placeholder || 'Select a date'
  };
  if (props.onBlur) attribs.onBlur = props.onBlur;

  return <DatePicker {...attribs} />;
};

function getYear(refdate) { return refdate.getFullYear(); }
function getMonth(refdate) { return refdate.getMonth(); }
function isNum(value) { return !(value===undefined || value===null || isNaN(value)); }

function range(startvalue, endvalue) {
  if (!isNum(startvalue)) return [];
  if (!isNum(endvalue)) endvalue = startvalue+1;
  const numrange = [];
  for (let i=startvalue, len=endvalue; i<=len; i++) numrange.push(i);
  return numrange;
}

function getYearsBack(yearsBack) {
  let items = [];
  const yearLimit = (isNum(yearsBack) ? yearsBack : 10);
  const startYear = new Date().getFullYear();
  for (let i=0; i<yearLimit; i++) items.push(startYear-i);
  return items;
}
