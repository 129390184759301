/**
 * Banner provides a container with a title, a button, and an image.
 * Supported props: title subtitle startstudy banner
 */
import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { css } from '../util/pagetools';
import Safe from './Safe';

export default function Banner(props) {
  const cfg = props.src;

  return (
    <div id="banner" className={css('banner', cfg.banner.theme)}>
      <div className="banner-left">
        <Safe className="banner-title" type="h1" content={cfg.title} />
        <Safe className="banner-text" content={cfg.subtitle} />
        <Link to="#screener" className={css('banner-btn', cfg.banner.btntheme)}>{cfg.startstudy}</Link>
      </div>
      <div>
        <StaticImage src='../images/banner.png' alt="main banner" layout="fullWidth" className="banner-img" />
        <StaticImage src='../images/banner_mobile.png' alt="main banner" layout="fullWidth" className="banner-img-mobile" />
      </div>
    </div>
  );
};
