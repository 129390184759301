/**
 * A component that provides a selection of sites.
 * Supported props: patientLoc locations setLocation
 */
import React from 'react';
import sortfn from '../util/geotools';
import Safe from './Safe';

export default function LocationsForm(props) {
  // Display only top 7 closest locations
  const siteLocations = (props.locations ? sortfn(props.patientLoc, props.locations.addresses) : []).filter((loc,idx) => idx<7);
  const ctabutton = props.locations.cta;
  function siteFn(site) { return function(e) { e.preventDefault(); props.setLocation(site); }; }

  return (
    <div className="locations">
        <div>
          {siteLocations.slice(0,4).map((item, index) => {
            return (
              <div key={`addr_${index}`} className="locations-row">
                  {renderSite(item, siteFn(item), ctabutton)}
              </div>
            );
          })}
        </div>
        <div>
          {siteLocations.slice(4).map((item, index) => {
            return (
              <div key={`addr_${index}`} className="locations-row">
                  {renderSite(item, siteFn(item), ctabutton)}
              </div>
            );
          })}
          <div className="locations-row no-site">
            <div className="loc-title">
              <strong><Safe type="p" content={props.locations.noSuitableSite} /></strong>
            </div>
            <button className="location-btn nopick" onClick={siteFn(null)}>{ctabutton}</button>
          </div>
        </div>
    </div>
  );
};

function renderSite(site, handler, buttonText) {
  return (
    <div className="location">
      <div>
        <div className="loc-title">
          <p><strong>{site.name}</strong></p>
        </div>
        <div className="street-block">
          <div className="thoroughfare">{site.street}</div>
        </div>
        <div className="addressfield-container-inline locality-block country-US">
          <span className="locality">{site.city}, </span>
          <span className="state">{site.state} </span>
          <span className="postal-code">{site.zip}</span>
        </div>
      </div>
      <div className="miles">{site.dist} Mi</div>
      <button className="location-btn locpick" onClick={handler}>{buttonText}</button>
    </div>
  );
}
