/* Home page */

import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import PageHead from '../components/PageHead';
import utm from '../util/utmtools';

export default function Home(props) {
  const ctx = props.pageContext;
  const sitedata = props.data.siteYaml;
  sitedata.language = ctx.locale;
  sitedata.utm = utm(props.location.href);

  return (
    <Layout config={sitedata} ctx={ctx} currentpath={props.path}>
      <PageHead lang={ctx.locale} sitename={sitedata.sitename} siteurl={sitedata.siteurl} meta={sitedata.metaDescription} />
    </Layout>
  );
};

export const query = graphql`
  query siteLocaleQ($locale: String, $variation: String) {
    siteYaml(locale: {eq: $locale}, variation: {eq: $variation}) {
      locale
      variation
      homelink
      sitename
      siteurl
      startstudy
      subtitle
      title
      metaDescription
      banner {
        theme
        btntheme
      }
      navbar {
        theme
        auxbuttonlabel
        auxbuttonurl
      }
      cookie {
        text
        linkText
        accept
        reject
      }
      fixedpanel {
        cta
        btntheme
        theme
      }
      sections {
        theme
        title
        header
        headerclass
        displayAs
        id
        linkto
        text
        data {
          content
          items
          theme
          cards {
            content
            image
            theme
            paragraphs    
          }
          card {
            title
            content
            image
            theme
          }
        }
      }
      footer {
        backToTop
        theme
        text
        links {
          label
          to
        }
      }
      errorpage {
        header
        subheader
      }
      form {
        button {
          theme
          label
          labelDq
        }
        formfields {
          id
          type
          label
          backend
          validation
          theme
          options {
            id
            value
          }
          default
          fullWidth
        }
      }
      screener {
        pid
        btntheme
        questions {
          q
          proceedIfYes
          theme
        }
        pages {
          id
          theme
          header
          message
        }
        questionLabel {
          affirm
          deny
          noun
          prep
        }
        successResult {
          btntheme
          locationpicked {
            theme
            header
            text
          }
          nolocation {
            theme
            header
            text
          }
        }
        failResult {
          theme
          btntheme
          header
          text
        }
        sitelocations {
          cta
          noSuitableSite
          addresses {
            name
            street
            city
            state
            zip
            lat
            lng
          }
        }
      }
    }
  }`;
