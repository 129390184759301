/**
 * Render a text field with a phone number mask.
 * Supported props: name value theme onChange
 */
import React from 'react';
import InputMask from 'react-input-mask';
import { css } from '../util/pagetools';

// Create a forward ref to enable accessing ref for focusing
const PhoneInput = React.forwardRef((props, ref) => (
  <InputMask
    mask="(999) 999-9999"
    name={props.name}
    id={props.name}
    value={props.value}
    className={css('form-control', props.theme)}
    aria-required="true"
    ref={ref}
    onChange={props.onChange}
    onBlur={props.onChange}>
  </InputMask>
));

export default PhoneInput;
