/**
 * NavToggleFlex renders a left-aligned logo and right-aligned toggle button.
 * At wide screen widths links are visible instead of the toggle button;
 * at narrow widths the button becomes visible and on click the links are
 * displayed vertically in another container.
 * Supported props: toplinks, sitename, ctx, currentpath, localizerfn, theme
 */
import React, { useState } from 'react';
import { Link } from 'gatsby';
import LinkMenu from './LinkMenu';
import logo from '../images/logo.png';
import LocaleSwitcher from './LocaleSwitcherModal';
import { css } from '../util/pagetools';

export default function Nav(props) {
  const locfn = props.localizerfn;
  const [open, setOpen] = useState(false);
  const vLinks = getNavLinks(props.toplinks, `nav-list`, `nav-item pary-on-white`, locfn, true);
  const hLinks = getNavLinks(props.toplinks, `nav-list horizontal`, `nav-item horizontal pary-on-white`, locfn);

  function openMenu(e) {
    const isExpanded = !open;
    let targetElem = e.target;
    if (targetElem.tagName!=='BUTTON') {
      targetElem = targetElem.closest('BUTTON');
    }
    if (isExpanded) targetElem.classList.add('open');
    else            targetElem.classList.remove('open');
    setOpen(!open);
  }

  return (
    <nav id="navbar-flx" className='nav-bar'> 
      <div className={css('nav-bar-container', props.navconfig.theme)}>
        <div className="navbar-logo-container">
          <Link to={locfn('/')} title={props.sitename}>
            <img src={logo} alt={props.sitename} className="nav-bar-logo" />
          </Link>
        </div>
        <div className="nav-bar-right">
          {hLinks}
          <a href={props.navconfig.auxbuttonurl} className='aux-button horizontal'>{props.navconfig.auxbuttonlabel}</a>
          <button className="navbar-btn" onClick={openMenu}>
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar top"></span>
            <span className="icon-bar middle"></span>
            <span className="icon-bar bottom"></span>
          </button>
        </div>
      </div>
      <div id="navbar-links" className={css('fg-1 navbar-links hid-md', (open?'open':''))}>
        {open && vLinks}
        {open && <a href={props.navconfig.auxbuttonurl} className='aux-button vertical' target="_blank" rel="noopener noreferrer">{props.navconfig.auxbuttonlabel}</a>}
      </div>
    </nav>
  );
};

function getNavLinks(links, liststyle, itemstyle, locfn, showVertical) {
  return (
    <LinkMenu links={links}
              listStyle={liststyle}
              itemStyle={css(itemstyle, (showVertical?'vertical':''))}
              localizerfn={locfn} />
  );
}
