/**
 * Combine the values of a selected set of fields into a single field.
 * @param object - the patient data
 * @param array - a list of field names
 * @param string - the key under which to save the combined values
 * @return object
 */
function combineFields(payload, fieldnames, saveAs) {
  if (!Array.isArray(fieldnames)) return payload;
  const data = Object.assign({}, payload);
  const combinedValues = fieldnames.reduce((values, key) => {
    if (data[key]) {
      values.push(data[key]);
      delete data[key];
    }
    return values;
  }, []);
  data[saveAs] = combinedValues.join(' | ');
  return data;
}


/**
 * Merge two fields of an object into a single one.
 * @param object - the source object
 * @param string - the name of the receiving field
 * @param string - the name of the field being merged into the receiving field
 * @param string - a default value in case no value exists for movingField
 * @return object
 */
function mergeFields(payload, receivingField, movingField, defaultValue) {
  const data = Object.assign({}, payload);
  data[receivingField] = `${data[movingField] | defaultValue} | ${data[receivingField]}`;
  if (data[movingField]) delete data[movingField];
  return data;
}

/**
 * Transform field names of an object according to a configuration file.
 * @param object - the source object
 * @param array - an array of configuration objects, [{id, backend}...]
 * @return object
 */
function mapFields(data, formfields) {
  if (!Array.isArray(formfields)) return data;
  const lookup = formfields.filter(cfg => cfg.backend)
    .map(cfg => ({id:cfg.id, backend:cfg.backend}))
    .reduce((newmap, cfg) => { newmap[cfg.id]=cfg.backend; return newmap; }, {});
  return Object.keys(data).reduce((target, field) => {
    target[(lookup[field] || field)] = data[field];
    return target;
  }, {});
}

export { combineFields, mergeFields, mapFields };
