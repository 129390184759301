/**
 * FB and GA tracking functions.
 */
const isBrowser = (typeof window !== 'undefined');

/**
 * Send data to Facebook.
 * @param boolean - is the candidate qualified
 */
function sendToFB(isQualified) {
  if (isBrowser && window.fbq) {
    window.fbq('trackCustom', 'PreScreenerFinish', {status: (isQualified ? 'PD' : 'DQ')});
  }
}

/**
 * Send data to Google Analytics for an individual screener question.
 * @param int - the index of the slide (zero-based)
 * @param boolean - whether or not candidate answered "Yes"
 */
function sendToGA(index, isAffirmative) {
  if (!isBrowser) return;
  const stage = (index===0 ? 'screener_start' : 'screener_progress');
  const evLabel = 'Q'+(index+1)+(isAffirmative?'Y':'N');
  if (window.gtag) {
    window.gtag('event', stage, { 'event_category': 'Screener', 'event_label': evLabel });
  }
}

/**
 * Send data to Google Analytics for screener conclusion.
 * @param boolean - is the candidate qualified
 */
function sendToGA_done(isQualified) {
  if (isBrowser && window.gtag) {
    window.gtag('event', 'screener_finish', { 'event_category': 'Screener', 'event_label': `Screener finished ${(isQualified ? 'Q' : 'DQ')}` });
  }
}

export { sendToFB, sendToGA, sendToGA_done };
