import React from 'react';

export default function SectionCard({cardData}) {
  return (
    <div className={cardData.theme} key={cardData.image}>
      <img src={cardData.image} alt="" />
      <div className={`${cardData.theme}-text`}>
        {cardData.title && <h3>{cardData.title}</h3>}
        {cardData.paragraphs ?
          cardData.paragraphs.map((paragraph, index) => <p key={`${cardData.image}-paragraph-${index}`}>{paragraph}</p>)
        :
          <p>{cardData.content}</p>
        }
      </div>
    </div>
  )
}
