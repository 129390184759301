import { Loader } from '@googlemaps/js-api-loader';

let apiClient = null;

export default function getGoogleMapsApiClient() {
  if (apiClient) return apiClient;
  const loader = new Loader({
    apiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
    version: 'weekly',
    libraries: ['places']
  });
  return loader.importLibrary('places');
};