/**
 * Render an address input form.
 * Supported props: id onChangeBlur onValidate focusRef format countries theme
 */
import React, {useEffect} from 'react';
import { css } from '../util/pagetools';
import extractAddressFields from '../util/placetools';
import getGoogleMapsApiClient from '../util/gmaploader';

export default function PlaceInput(props) {
  const elemID = props.id || 'place-autocomplete';
  const settings = {
    componentRestrictions: { country: (Array.isArray(props.countries) ? props.countries : ['us'])},
    types: props.format || ['address'],
    fields: ['geometry', 'formatted_address', 'address_components']
  };
  const fieldRef = props.focusRef;
  const compOptions = {
    id: elemID,
    name: elemID,
    type: 'text',
    onBlur: onLeave,
    onChange: onAlter,
    ref: fieldRef,
    className: css('form-control', props.theme),
    placeholder: 'Enter an address',
    'aria-required': true
  };
  let autocomplete = null;

  function onPlaceChanged() {
    if (!autocomplete) return;
    const place = autocomplete.getPlace();
    const locdata = (place.geometry ? extractAddressFields(place) : null);
    if (!locdata) {
      fieldRef.current.value = '';
      return;
    }
    // Send location data to parent to be saved
    if (props.onChangeBlur) props.onChangeBlur(locdata);
    fieldRef.current.blur();
  }

  function onLeave(e) {
    const isValid = (props.onValidate ? props.onValidate(e) : e.target.value.trim().length>0);
    if (!isValid) fieldRef.current.value = '';
  }

  // Invalidate location data on any change
  function onAlter(e) { props.onChangeBlur(null); }

  function initializeAutocomplete(api) {
    const input = document.getElementById(elemID);
    if (api && input) {
      autocomplete = new api.Autocomplete(input, settings);
      autocomplete.addListener('place_changed', onPlaceChanged);
      attributeOverride(elemID, 'autocomplete', 'new-password');
    }
  }

  useEffect(() => {
    async function getApi() { 
      const api = await getGoogleMapsApiClient();
      initializeAutocomplete(api);
    }
    getApi().catch(console.warn);
  });

  return <input {...compOptions} />;
};

function attributeOverride(id, name, value) {
  const elem = document.getElementById(id);
  const observerHack = new MutationObserver(function() {
    observerHack.disconnect();
    elem.setAttribute(name, value);
  });
  observerHack.observe(elem, {attributes:true, attributeFilter:[name]});
}
