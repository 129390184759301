/* Dropdown implements an HTML select component
   Supported props: name items defaultItem theme errortheme onChangeBlur focusRef errorFn errorMessage value
*/

import React from 'react';
import Safe from './Safe';

function defaultHandler(e) { console.log(`-> ${e.target.value}`); }

export default function Dropdown(props) {
  const handler = props.onChangeBlur || defaultHandler;
  const attribs = {
    name: props.name,
    onChange: handler,
    onBlur: handler
  };
  if (props.theme) attribs.className = props.theme;
  if (props.focusRef) attribs.ref = props.focusRef;
  if (props.value) attribs.value = props.value;
  if (props.id) attribs.id = props.id;
  const errortheme = props.errortheme || 'error-msg';

  if (props.errorFn) {
    return (
      <>
        <select {...attribs}>
          {props.defaultItem && <Safe type="option" value="" content={props.defaultItem} />}
          {fillOptions(props.items)}
        </select>
        <div className={`${errortheme} ${props.errorFn(props.name)?'vis':'hid'}`}>{props.errorMessage || 'This field is required'}</div>
      </>
    );
  }
  return (
    <select {...attribs}>
      {props.defaultItem && <option value="">{props.defaultItem}</option>}
      {fillOptions(props.items)}
    </select>
  );
};

function fillOptions(items) {
  if (!Array.isArray(items) || items.length===0) return [<option key="opt_0" value="">?</option>];
  if ((typeof items[0])!=='object') {
    return items.map((item, index) => <Safe key={`opt_${index}`} type="option" value={item} content={item} />);
  }
  return items.map((item, index) => {
    // Render options from array of [{id,value}]
    if (item.hasOwnProperty('id')) {
      return <Safe type="option" key={`opt_${index}`} value={item.id} content={item.value} />;
    }
    return <Safe type="option" key={`opt_${index}`} value={item.value} content={item.value} />;
  });
}
