
import cookieMgr from 'js-cookie';

const cookiename = 'UTM_INFO';
const cookiefields = ['utm_source', 'utm_medium', 'utm_content', 'utm_campaign', 'mpt'];

export default function(pagepath) {
  const valueFromUrlOrCookie = parseparams(pagepath) || cookieMgr.get(cookiename);
  const validatedValue = (valueFromUrlOrCookie==='undefined' ? '': valueFromUrlOrCookie);
  // console.log(`Cookie ${cookiename}, store value as ${validatedValue}`);
  return validatedValue;
};

/**
 * Parse url into a delimited string of parameters, filtering out any unregistered values.
 * @param string - the query string parameters received from the referrer
 * @return string
 */
function parseparams(source) {
  if (!source) return '';
  const parts = source.split('?');
  if (parts.length<2) return '';
  return parts[1].split('&').filter(pair => cookiefields.includes(pair.split('=')[0])).join('|');
}
