/**
 * Render a list of checkboxes
 * Supported props: items onChange
 *   items = array of object {id, value}
 */
import React from 'react';
import Safe from './Safe';

export default function CheckList(props) {
  if (!Array.isArray(props.items)) {
    return <div>Misconfigured checkbox list - no items defined.</div>;
  }
  const [choices, setChoices] = React.useState([]);

  function registerSelection(itemPicked) {
    let currlist = null;
    if (itemPicked.checked) {
      if (!choices.includes(itemPicked.id)) currlist = choices.concat(itemPicked.id);
    } else {
      if (choices.includes(itemPicked.id)) currlist = choices.filter(id => id!==itemPicked.id);
    }
    props.onChange(currlist);
    setChoices(currlist);
  }

  return (
    <>
      {props.items.map((cfg, idx) => <Checkbox key={`ck-${idx}`} name={cfg.id} label={cfg.value} onChange={registerSelection} />)}
    </>
  );
};

/**
 * Render a check box.
 * Supported props: name label onChange theme
 */
function Checkbox(props) {
  const [confirmed, setConfirmed] = React.useState(false);

  function handleChange(e) {
    const currvalue = !confirmed;
    setConfirmed(currvalue);
    props.onChange({id:props.name, checked:currvalue});
  }

  const attribs = {
    type: 'checkbox',
    id: props.name,
    name: props.name,
    checked: confirmed,
    onChange: handleChange
  };
  if (props.theme) attribs.className = props.theme;

  return (
    <div className="checkbox-group">
      <label htmlFor={props.name}>
        <input {...attribs} />
        <span className="pl-15">{props.label}</span>
      </label>
    </div>
  );
};
